import Highlight from '@tiptap/extension-highlight'
const customHighlight = Highlight.extend({
  addAttributes() {
    if (!this.options.multicolor) {
      return {}
    }

    return {
      color: {
        default: null,
        parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: attributes => {
          if (!attributes.color) {
            return {}
          }

          return {
            'data-color': attributes.color,
            style: `background-color: ${attributes.color}4D; color: #000000`
          }
        }
      }
    }
  }
})

export default customHighlight
