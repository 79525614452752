import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'

import MentionList from './MentionList.vue'

export default function createBlockSuggestions(options = {}) {
  const allowedTypes = options.allowedTypes || null

  return {
    items: ({ query }) => {
      const allItems = [
        {
          name: {
            en: 'Image',
            pt: 'Imagem'
          },
          type: 'image'
        },
        {
          name: {
            en: 'Video',
            pt: 'Vídeo'
          },
          type: 'video'
        },
        {
          name: {
            en: 'Youtube Video',
            pt: 'Vídeo do Youtube'
          },
          type: 'youtube'
        },
        {
          name: {
            en: 'Quote',
            pt: 'Citação'
          },
          type: 'blockquote'
        },
        {
          name: {
            en: 'List',
            pt: 'Lista'
          },
          type: 'bulletList'
        },
        {
          name: {
            en: 'Ordered List',
            pt: 'Lista ordenada'
          },
          type: 'orderedList'
        },
        {
          name: {
            en: 'Title 1',
            pt: 'Título 1'
          },
          type: 'heading',
          attrs: {
            level: 1
          }
        },
        {
          name: {
            en: 'Title 2',
            pt: 'Título 2'
          },
          type: 'heading',
          attrs: {
            level: 2
          }
        },
        {
          name: {
            en: 'Title 3',
            pt: 'Título 3'
          },
          type: 'heading',
          attrs: {
            level: 3
          }
        },
        {
          name: {
            en: 'Code',
            pt: 'Código'
          },
          type: 'codeBlock'
        },
        {
          name: {
            en: 'Files section',
            pt: 'Seção de ficheiros'
          },
          type: 'file'
        },
        {
          name: {
            en: 'Horizontal Line',
            pt: 'Linha Horizontal'
          },
          type: 'horizontalRule'
        }
      ]

      const filteredItems = allowedTypes ? allItems.filter(item => allowedTypes.includes(item.type)) : allItems

      return filteredItems.filter(
        item =>
          item.name['pt'].toLowerCase().startsWith(query.toLowerCase()) ||
          item.name['en'].toLowerCase().startsWith(query.toLowerCase())
      )
    },

    render: () => {
      let component
      let popup

      return {
        onStart: props => {
          component = new VueRenderer(MentionList, {
            parent: this,
            propsData: props,
            editor: props.editor
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start'
          })
        },

        onUpdate(props) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            return true
          }

          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        }
      }
    }
  }
}
